import Layout, { Stack } from '@/components/Layout'
import MenuLinkInternal from '@/components/MenuLinkInternal'
import MenuLink from '@/components/MenuLinks'
import ParentMenuItem from '@/components/ParentMenuItem'
import { rbp } from '@/constants/measured-scope'
import { useFeatureToggle } from '@/context/featureToggles'
import SearchBox from '@/widgets/SearchBox'
import { useMediaQuery } from '@react-hookz/web'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef, useState } from 'react'
import * as styles from './style'

interface BaseNavigationMenuItem {
  label: string
  active?: boolean
  isExternal?: boolean
  shouldBeAuthenticatedUser?: boolean
  hideOnMobile?: boolean
  isBeta?: boolean
  isNew?: boolean
}

interface InternalNavigationMenuItem extends BaseNavigationMenuItem {
  type: 'InternalNavigationMenuItem'
  url: string
}

interface ParentNavigationMenuItem extends BaseNavigationMenuItem {
  type: 'ParentNavigationMenuItem'
  submenu: InternalNavigationMenuItem[]
}

type NavigationLink = InternalNavigationMenuItem | ParentNavigationMenuItem

const SecondaryNavigation = (): JSX.Element => {
  const router = useRouter()
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.lg})`, { initializeWithValue: false })
  const [openDropdown, setOpenDropdown] = useState<number | null>(null) // State for dropdown visibility
  const dropdownRef = useRef<HTMLDivElement>(null) // Ref for dropdown container
  const showHelenTab = useFeatureToggle('helen_tab')

  const isActive = useCallback(
    (url?: string): boolean => {
      if (!router.isReady) return false
      if (url === '/' && router.pathname === '/partner/[slug]') return true
      if (router.asPath === '/series/socialconnections') {
        return url === '/series/socialconnections' // Community button should be active when on community page
      }
      if (url === '/' && router.pathname === '/exp-home') return true
      if (url === '/' && router.pathname !== '/') return false
      return router.asPath === url || router.asPath.startsWith(`${url}/`)
    },
    [router.isReady, router.asPath],
  )

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setOpenDropdown(null)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  function MenuLinks(): NavigationLink[] {
    const { t } = useTranslation('common')
    let menuLinks: NavigationLink[] = []
    if (showHelenTab) {
      menuLinks = [
        { type: 'InternalNavigationMenuItem', label: t('nav.home'), url: '/', hideOnMobile: true },
        {
          type: 'ParentNavigationMenuItem',
          label: t('nav.classes'),
          submenu: [
            { type: 'InternalNavigationMenuItem', label: t('nav.calendar'), url: '/schedule/browse' },
            { type: 'InternalNavigationMenuItem', label: t('nav.mySchedule'), url: '/schedule/my-schedule' },
            { type: 'InternalNavigationMenuItem', label: t('nav.videos'), url: '/videos' },
            { type: 'InternalNavigationMenuItem', label: t('nav.medicare'), url: '/medicare' },
          ],
        },
        { type: 'InternalNavigationMenuItem', label: t('nav.articles'), url: '/articles', isNew: true },
        { type: 'InternalNavigationMenuItem', label: t('nav.series'), url: '/series' },
        { type: 'InternalNavigationMenuItem', label: t('search.guides'), url: '/guides' },
        { type: 'InternalNavigationMenuItem', label: t('nav.community'), url: '/series/socialconnections' },
      ]
      if (isLargeDevice)
        menuLinks.push({ type: 'InternalNavigationMenuItem', label: t('nav.helen'), url: '/helen', isNew: true })
    } else {
      menuLinks = [
        { type: 'InternalNavigationMenuItem', label: t('nav.home'), url: '/', hideOnMobile: true },
        { type: 'InternalNavigationMenuItem', label: t('nav.calendar'), url: '/schedule/browse' },
        { type: 'InternalNavigationMenuItem', label: t('nav.mySchedule'), url: '/schedule/my-schedule' },
        { type: 'InternalNavigationMenuItem', label: t('nav.videos'), url: '/videos' },
        { type: 'InternalNavigationMenuItem', label: t('nav.articles'), url: '/articles', isNew: true },
        { type: 'InternalNavigationMenuItem', label: t('nav.series'), url: '/series' },
        { type: 'InternalNavigationMenuItem', label: t('search.guides'), url: '/guides' },
        { type: 'InternalNavigationMenuItem', label: t('nav.community'), url: '/series/socialconnections' },
        { type: 'InternalNavigationMenuItem', label: t('nav.medicare'), url: '/medicare', isNew: true },
      ]
    }

    return menuLinks
  }

  const menuLinks = MenuLinks()

  return (
    <Layout className={styles.wrapper}>
      <Stack className={styles.container} x alignItems="flex-start" justifyContent="space-between">
        <Stack className={styles.navigation} x>
          {menuLinks.map((menuLink, i) => {
            const isParentActive = 'submenu' in menuLink && menuLink.submenu.some((subLink) => isActive(subLink.url))
            //2 cases here: either the menu will have an 'url' or 'submenu' in it
            if ('url' in menuLink) {
              if (menuLink.isExternal) {
                return (
                  <MenuLink
                    key={`menu-link-external-${i}`}
                    hasPopOut={true}
                    label={menuLink.label}
                    url={menuLink.url}
                    openInNewTab={false}
                  />
                )
              } else if (isLargeDevice || !menuLink.hideOnMobile) {
                return (
                  <MenuLinkInternal
                    key={`menu-link-internal-${i}`}
                    active={isActive(menuLink.url)}
                    label={menuLink.label}
                    url={menuLink.url}
                    isBeta={menuLink.isBeta}
                    isNew={menuLink.isNew}
                  />
                )
              }
            } else if ('submenu' in menuLink) {
              return (
                <div
                  key={`menu-link-dropdown-${i}`}
                  className={styles.dropdown}
                  onClick={() => setOpenDropdown(openDropdown === i ? null : i)}
                  ref={openDropdown === i ? dropdownRef : null}
                >
                  <ParentMenuItem active={isParentActive} label={menuLink.label} />
                  {openDropdown === i && (
                    <div className={styles.dropdownMenu}>
                      {menuLink.submenu.map((subLink, subIndex) => (
                        <MenuLinkInternal
                          key={`menu-link-submenu-${subIndex}`}
                          active={isActive(subLink.url)}
                          label={subLink.label}
                          url={subLink.url}
                          className={styles.dropdownMenuItem}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )
            }
          })}
        </Stack>
        {isLargeDevice && (
          <div className={styles.searchContainer}>
            <SearchBox hasResetOption isLargeDevice />
          </div>
        )}
      </Stack>
    </Layout>
  )
}
export default SecondaryNavigation
