import Avatar from '@/components/Avatar'
import IcoMoon from '@/components/IcoMoon'
import Icon from '@/components/Icon'
import { useAuth } from '@/context/auth'
import { useFeatureToggle } from '@/context/featureToggles'
import UserCardNext from '@/widgets/UserCardNext'
import { useClickOutside } from '@react-hookz/web'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRef, useState } from 'react'
import * as styles from './style'

export interface Props {
  firstName?: string
  fullName?: string
}

const UserMenu = ({ firstName, fullName }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const { dispatch } = useAuth()
  const userPopoutRef = useRef(null)
  const [userPopoutVisible, setUserPopoutVisible] = useState(false)
  const canSeeClassNotes = useFeatureToggle('eng_class_notes')
  const showHelenTab = useFeatureToggle('helen_tab')

  useClickOutside(userPopoutRef, toggleUserPopout)

  function toggleUserPopout(): void {
    setUserPopoutVisible(!userPopoutVisible)
  }

  return (
    <>
      <div onClick={toggleUserPopout} className={styles.root}>
        <UserCardNext
          avatar={<Avatar title={fullName} size="sm" />}
          theme="primaryNext"
          title={firstName}
          iconRight={
            <Icon className={styles.chevron} name={userPopoutVisible ? 'chevron-black-up' : 'chevron-black-down'} />
          }
        />
      </div>
      {userPopoutVisible && (
        <div className={styles.userPopout}>
          <nav ref={userPopoutRef}>
            <ul>
              {canSeeClassNotes && (
                <li>
                  <Link href="/notes" prefetch={false}>
                    <IcoMoon icon="notes" size={16} className="mr-xxs" />
                    {t('nav.classNotes')}
                  </Link>
                </li>
              )}
              <li>
                <Link href="/schedule/my-schedule" prefetch={false}>
                  <IcoMoon icon="calendar" size={16} className="mr-xxs" />
                  {t('nav.mySchedule')}
                </Link>
              </li>
              {showHelenTab && (
                <li>
                  <Link href="/reminders" prefetch={false}>
                    <IcoMoon icon="alarm" size={16} className="mr-xxs" />
                    {t('nav.reminders')}
                  </Link>
                </li>
              )}
              <li>
                <Link href="/account-settings" prefetch={false}>
                  <IcoMoon icon="settings" size={16} className="mr-xxs" />
                  {t('nav.settings')}
                </Link>
              </li>
              <li>
                <Link href="#" onClick={() => dispatch({ type: 'LOGOUT', payload: { returnHome: true } })}>
                  <IcoMoon icon="logout" size={16} className="mr-xxs" />
                  {t('nav.logOut')}
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  )
}
export default UserMenu
