import { rbp } from '@/constants/measured-scope'
import { useMediaQuery } from '@react-hookz/web'
import classNames from 'classnames/bind'
import { useTranslation } from 'react-i18next'
import * as styles from './style'

const cx = classNames.bind(styles)

export interface Props {
  active?: boolean
  blip?: boolean
  className?: string
  label: string
  isBeta?: boolean
  isNew?: boolean
}

const ParentMenuItem = ({ active = false, blip = false, className = '', label, isBeta, isNew }: Props): JSX.Element => {
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.tablet})`, { initializeWithValue: false })
  const { t } = useTranslation('common')

  const classes = cx({
    [`${className}`]: true,
  })

  return (
    <div className={`${styles.root} ${classes}`}>
      {isBeta && (
        <div className={styles.betaTag}>
          <span>{t('beta')}</span>
        </div>
      )}
      {isNew && (
        <div className={styles.newTag}>
          <span>{t('new')}</span>
        </div>
      )}
      {label}
      {isLargeDevice && active && <span className={styles.active} />}
      {!isLargeDevice && active && <span className={styles.active} />}
      {blip && <span className={styles.blip} />}
    </div>
  )
}
export default ParentMenuItem
